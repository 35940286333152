<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>
      <h3 v-if="add_new" style="position: relative;bottom: 9px;">Add Event</h3>
      <h3 v-if="add_update" style="position: relative;bottom: 9px;">Edit Event</h3>

      <!-- PROFILE HEADER -->
      <div class="profile-header">
          <div class="relative">
              <div class="cover-container rounded-t-lg">
                  <img :src="formData['image_url']||'/600x400.png'" alt="user-profile-cover" class="responsive block" style="height: 400px;width:100%;margin: 0 auto;">
              </div>
              <div class="profile-img-container pointer-events-none" style="position: absolute;bottom: -18px; right:475px;">
                  <div class="profile-actions pointer-events-auto flex" v-if="add_image_after">
                      <input type="file" class="hidden" ref="org_profile_image" @change="remove_images" accept=".png,.jpg,.jpeg">
                      <vs-button icon-pack="feather" color="danger" v-if="add_image_after" radius icon="icon-edit-2" @click="$refs.org_profile_image.click()"></vs-button>

                  </div>

                    <div class="profile-actions pointer-events-auto flex"  v-if="add_image_before">
                      <input type="file" class="hidden" ref="org_profile_image" @change="update_avatar" accept=".png,.jpg,.jpeg">
                      <vs-button icon-pack="feather"  v-if="add_image_before" radius icon="icon-edit-2" @click="$refs.org_profile_image.click()"></vs-button>

                  </div>
              </div>
          </div>
      </div>

      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4 required" label="Title" :maxlength=40  placeholder="Title" v-model="formData['title']" v-validate="'required'" name="title" />
          <span class="text-danger text-sm"  v-show="errors.has('title')">{{ errors.first('title') }}</span>

          
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Address <span class="text-danger"> * </span></label>
            <vs-textarea :maxlength=240 rows="3" v-model="formData['address']" placeholder="Address" />
            <span class="text-danger text-sm"  v-show="errors.has('address')">{{ errors.first('address') }}</span>
          </div>

          <div class="mt-4">
          <label class="text-sm required" style="margin-left: 4px;">Time Zone <span class="text-danger">*</span></label>
          <v-select :name="timezone" v-model="timezone"  :clearable="false" :options="roleOptions" v-validate="'required'" name="timezone" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('timezone')">{{ errors.first('timezone') }}</span>
        </div>

          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Event Date <span class="text-danger"> * </span></label>
            <flat-pickr v-if="reset_btn" v-model="event_time" :config="{minDate: 'today'}" class="w-full" v-validate="'required'" name="event_time" />
            <flat-pickr v-if="canel_btn" v-model="event_time" :config="{}" class="w-full" v-validate="'required'" name="event_time" />
            <span class="text-danger text-sm"  v-show="errors.has('event_time')">{{ errors.first('event_time') }}</span>
          </div>
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Event Start Time <span class="text-danger"> * </span></label>
            <flat-pickr v-model="event_start_time_model" :config="{ enableTime: true,dateFormat: 'H:i',noCalendar: true}" class="w-full" v-validate="'required'" name="event_start_time" />
            <span class="text-danger text-sm"  v-show="errors.has('event_start_time')">{{ errors.first('event_start_time') }}</span>
          </div>
          
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Event End Time <span class="text-danger"> * </span></label>
            <flat-pickr v-model="event_end_time_model" :config="{ enableTime: true,dateFormat: 'H:i',noCalendar: true}" class="w-full" v-validate="'required'" name="event_end_time" />
            <span class="text-danger text-sm"  v-show="errors.has('event_end_time')">{{ errors.first('event_end_time') }}</span>
            <span class="text-danger text-sm" v-if="errEndTime">Start Time Must Always Greater Than End Time....!!!</span>
          </div>

          
             <div class="mt-4">
           <div class="flex items-start flex-col sm:flex-row">
            <img :src="formData['image_url_list'] ||'/400x400.png'" class="mr-8 rounded h-24 w-24"  />

            <!-- <vs-avatar :src="data.avatar" size="80px" class="mr-4" /> -->
          <div class="mt-4"  v-if="add_image_list" >
              <input type="file" class="hidden" ref="image_url_list" @change="update_avatar_list" accept=".png,.jpg,.jpeg" v-validate="'required'">
              <vs-button class="mr-4 mb-4" v-if="add_image_list"  @click="$refs.image_url_list.click()">Add Image</vs-button>
          </div>
           <div class="mt-4" v-if="remove_image_list" > 
              <input type="file" class="hidden" ref="image_url_list" @change="remove_images_list" accept=".png,.jpg,.jpeg" v-validate="'required'">
              <vs-button type="border" color="danger"  v-if="remove_image_list" @click="$refs.image_url_list.click()">Replace Image</vs-button>
          </div>
          </div>
          </div>

        </div>

        <div class="vx-col md:w-1/2 w-full">

          <div class="mt-4">
          <label class="text-sm" style="margin-left: 4px;">Contact Info <span class="text-danger"> * </span></label>
            <vs-textarea maxlength="240" rows="3" v-model="formData['contact_info']" placeholder="Contact Info" v-validate="'required'" name="contact_info" />
            <span class="text-danger text-sm"  v-show="errors.has('contact_info')">{{ errors.first('contact_info') }}</span>
        </div>

          <div class="mt-4">
          <label class="text-sm" style="margin-left: 4px;">Description <span class="text-danger"> * </span></label>
            <vs-textarea :maxlength=240 rows="12" class="vs-input"  name="description" v-validate="'required'"  v-model="formData['description']" placeholder="Description..." />
            <span class="text-danger text-sm"  v-show="errors.has('description')">{{ errors.first('description') }}</span>
          </div>
          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Status</label>
            <vs-switch color="success" v-model="status_model">
                <span slot="on">Active</span>
                <span slot="off">InActive</span>
            </vs-switch>
          </div>
        </div>
      </div>

      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8  flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm">Save Changes</vs-button>
            <vs-button class="ml-4 mt-2" v-if="reset_btn" color="warning" @click="reset_data">Reset</vs-button>
            <vs-button class="ml-4 mt-2" v-if="canel_btn" color="warning" @click="cancel_data">Cancel</vs-button>
          </div>
        </div>
      </div>

    </vx-card>
</template>

<script>
// import moduleEvent          from '@/store/event/moduleEvent.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  data () {
    return {
      formData      : {},
        add_image_before: true,
       add_image_after : '',
       upload_image:0,
         add_new : true,
          add_image_list: true,
       remove_image : '',
       remove_image_list : '',
       add_update :false,
         reset_btn : true,
       canel_btn : false,
        errEndTime:false,
        roleOptions: [
            { label: 'Europe/AndorraEurope/Andorra',  value: 'Europe/Andorra' },
            { label: 'Asia/Dubai',  value: 'Asia/Dubai' },
            { label: 'Asia/Kabul',  value: 'Asia/Kabul' },
            { label: 'Europe/Tirane',  value: 'Europe/Tirane' },
            { label: 'Asia/Yerevan',  value: 'Asia/Yerevan' },
            { label: 'Antarctica/Casey',  value: 'Antarctica/Casey' },
            { label: 'Antarctica/Davis',  value: 'Antarctica/Davis' },
            { label: 'Antarctica/DumontDUrville',  value: 'Antarctica/DumontDUrville' },
            { label: 'Antarctica/Mawson',  value: 'Antarctica/Mawson' },
            { label: 'Antarctica/Palmer',  value: 'Antarctica/Palmer' },
            { label: 'Antarctica/Rothera',  value: 'Antarctica/Rothera' },
            { label: 'Antarctica/Syowa',  value: 'Antarctica/Syowa' },
            { label: 'Antarctica/Troll',  value: 'Antarctica/Troll' },
            { label: 'Antarctica/Vostok',  value: 'Antarctica/Vostok' },
            { label: 'America/Argentina/Buenos_Aires',  value: 'America/Argentina/Buenos_Aires' },
            { label: 'America/Argentina/Cordoba',  value: 'America/Argentina/Cordoba' },
            { label: 'America/Argentina/Salta',  value: 'America/Argentina/Salta' },
            { label: 'America/Argentina/Jujuy',  value: 'America/Argentina/Jujuy' },
            { label: 'America/Argentina/Tucuman',  value: 'America/Argentina/Tucuman' },
            { label: 'America/Argentina/Catamarca',  value: 'America/Argentina/Catamarca' },
            { label: 'America/Argentina/La_Rioja',  value: 'America/Argentina/La_Rioja' },
            { label: 'America/Argentina/San_Juan',  value: 'America/Argentina/San_Juan' },
            { label: 'America/Argentina/Mendoza',  value: 'America/Argentina/Mendoza' },
            { label: 'America/Argentina/San_Luis',  value: 'America/Argentina/San_Luis' },
            { label: 'America/Argentina/Rio_Gallegos',  value: 'America/Argentina/Rio_Gallegos' },
            { label: 'America/Argentina/Ushuaia',  value: 'America/Argentina/Ushuaia' },
            { label: 'Pacific/Pago_Pago',  value: 'Pacific/Pago_Pago' },
            { label: 'Europe/Vienna',  value: 'Europe/Vienna' },
            { label: 'Australia/Lord_Howe',  value: 'Australia/Lord_Howe' },
            { label: 'Australia/Macquarie',  value: 'Australia/Macquarie' },
            { label: 'Australia/Hobart',  value: 'Australia/Hobart' },
            { label: 'Australia/Currie',  value: 'Australia/Currie' },
            { label: 'Australia/Melbourne',  value: 'Australia/Melbourne' },
            { label: 'Australia/Sydney',  value: 'Australia/Sydney' },
            { label: 'Australia/Broken_Hill',  value: 'Australia/Broken_Hill' },
            { label: 'Australia/Brisbane',  value: 'Australia/Brisbane' },
            { label: 'Australia/Lindeman',  value: 'Australia/Lindeman' },
            { label: 'Australia/Adelaide',  value: 'Australia/Adelaide' },
            { label: 'Australia/Darwin',  value: 'Australia/Darwin' },
            { label: 'Australia/Perth',  value: 'Australia/Perth' },
            { label: 'Australia/Eucla',  value: 'Australia/Eucla' },
            { label: 'Asia/Baku',  value: 'Asia/Baku' },
            { label: 'America/Barbados',  value: 'America/Barbados' },
            { label: 'Asia/Dhaka',  value: 'Asia/Dhaka' },
            { label: 'Europe/Brussels',  value: 'Europe/Brussels' },
            { label: 'Europe/Sofia',  value: 'Europe/Sofia' },
            { label: 'Atlantic/Bermuda',  value: 'Atlantic/Bermuda' },
            { label: 'Asia/Brunei',  value: 'Asia/Brunei' },
            { label: 'America/La_Paz',  value: 'America/La_Paz' },
            { label: 'America/Noronha',  value: 'America/Noronha' },
            { label: 'America/Belem',  value: 'America/Belem' },
            { label: 'America/Fortaleza',  value: 'America/Fortaleza' },
            { label: 'America/Recife',  value: 'America/Recife' },
            { label: 'America/Araguaina',  value: 'America/Araguaina' },
            { label: 'America/Maceio',  value: 'America/Maceio' },
            { label: 'America/Bahia',  value: 'America/Bahia' },
            { label: 'America/Sao_Paulo',  value: 'America/Sao_Paulo' },
            { label: 'America/Campo_Grande',  value: 'America/Campo_Grande' },
            { label: 'America/Cuiaba',  value: 'America/Cuiaba' },
            { label: 'America/Santarem',  value: 'America/Santarem' },
            { label: 'America/Porto_Velho',  value: 'America/Porto_Velho' },
            { label: 'America/Boa_Vista',  value: 'America/Boa_Vista' },
            { label: 'America/Manaus',  value: 'America/Manaus' },
            { label: 'America/Eirunepe',  value: 'America/Eirunepe' },
            { label: 'America/Rio_Branco',  value: 'America/Rio_Branco' },
            { label: 'America/Nassau',  value: 'America/Nassau' },
            { label: 'Asia/Thimphu',  value: 'Asia/Thimphu' },
            { label: 'Europe/Minsk',  value: 'Europe/Minsk' },
            { label: 'America/Belize',  value: 'America/Belize' },
            { label: 'America/St_Johns',  value: 'America/St_Johns' },
            { label: 'America/Halifax',  value: 'America/Halifax' },
            { label: 'America/Glace_Bay',  value: 'America/Glace_Bay' },
            { label: 'America/Moncton',  value: 'America/Moncton' },
            { label: 'America/Goose_Bay',  value: 'America/Goose_Bay' },
            { label: 'America/Blanc-Sablon',  value: 'America/Blanc-Sablon' },
            { label: 'America/Toronto',  value: 'America/Toronto' },
            { label: 'America/Nipigon',  value: 'America/Nipigon' },
            { label: 'America/Thunder_Bay',  value: 'America/Thunder_Bay' },
            { label: 'America/Iqaluit',  value: 'America/Iqaluit' },
            { label: 'America/Pangnirtung',  value: 'America/Pangnirtung' },
            { label: 'America/Atikokan',  value: 'America/Atikokan' },
            { label: 'America/Winnipeg',  value: 'America/Winnipeg' },
            { label: 'America/Rainy_River',  value: 'America/Rainy_River' },
            { label: 'America/Resolute',  value: 'America/Resolute' },
            { label: 'America/Rankin_Inlet',  value: 'America/Rankin_Inlet' },
            { label: 'America/Regina',  value: 'America/Regina' },
            { label: 'America/Swift_Current',  value: 'America/Swift_Current' },
            { label: 'America/Edmonton',  value: 'America/Edmonton' },
            { label: 'America/Cambridge_Bay',  value: 'America/Cambridge_Bay' },
            { label: 'America/Yellowknife',  value: 'America/Yellowknife' },
            { label: 'America/Inuvik',  value: 'America/Inuvik' },
            { label: 'America/Creston',  value: 'America/Creston' },
            { label: 'America/Dawson_Creek',  value: 'America/Dawson_Creek' },
            { label: 'America/Fort_Nelson',  value: 'America/Fort_Nelson' },
            { label: 'America/Vancouver',  value: 'America/Vancouver' },
            { label: 'America/Whitehorse',  value: 'America/Whitehorse' },
            { label: 'America/Dawson',  value: 'America/Dawson' },
            { label: 'Indian/Cocos',  value: 'Indian/Cocos' },
            { label: 'Europe/Zurich',  value: 'Europe/Zurich' },
            { label: 'Africa/Abidjan',  value: 'Africa/Abidjan' },
            { label: 'Pacific/Rarotonga',  value: 'Pacific/Rarotonga' },
            { label: 'America/Santiago',  value: 'America/Santiago' },
            { label: 'America/Punta_Arenas',  value: 'America/Punta_Arenas' },
            { label: 'Pacific/Easter',  value: 'Pacific/Easter' },
            { label: 'Asia/Shanghai',  value: 'Asia/Shanghai' },
            { label: 'Asia/Urumqi',  value: 'Asia/Urumqi' },
            { label: 'America/Bogota',  value: 'America/Bogota' },
            { label: 'America/Costa_Rica',  value: 'America/Costa_Rica' },
            { label: 'America/Havana',  value: 'America/Havana' },
            { label: 'Atlantic/Cape_Verde',  value: 'Atlantic/Cape_Verde' },
            { label: 'America/Curacao',  value: 'America/Curacao' },
            { label: 'Indian/Christmas',  value: 'Indian/Christmas' },
            { label: 'Asia/Nicosia',  value: 'Asia/Nicosia' },
            { label: 'Asia/Famagusta',  value: 'Asia/Famagusta' },
            { label: 'Europe/Prague',  value: 'Europe/Prague' },
            { label: 'Europe/Berlin',  value: 'Europe/Berlin' },
            { label: 'Europe/Copenhagen',  value: 'Europe/Copenhagen' },
            { label: 'America/Santo_Domingo',  value: 'America/Santo_Domingo' },
            { label: 'America/Guayaquil',  value: 'America/Guayaquil' },
            { label: 'Africa/Algiers',  value: 'Africa/Algiers' },
            { label: 'Pacific/Galapagos',  value: 'Pacific/Galapagos' },
            { label: 'Europe/Tallinn',  value: 'Europe/Tallinn' },
            { label: 'Africa/Cairo',  value: 'Africa/Cairo' },
            { label: 'Africa/El_Aaiun',  value: 'Africa/El_Aaiun' },
            { label: 'Europe/Madrid',  value: 'Europe/Madrid' },
            { label: 'Africa/Ceuta',  value: 'Africa/Ceuta' },
            { label: 'Atlantic/Canary',  value: 'Atlantic/Canary' },
            { label: 'Europe/Helsinki',  value: 'Europe/Helsinki' },
            { label: 'Pacific/Fiji',  value: 'Pacific/Fiji' },
            { label: 'Atlantic/Stanley',  value: 'Atlantic/Stanley' },
            { label: 'Pacific/Chuuk',  value: 'Pacific/Chuuk' },
            { label: 'Pacific/Pohnpei',  value: 'Pacific/Pohnpei' },
            { label: 'Pacific/Kosrae',  value: 'Pacific/Kosrae' },
            { label: 'Atlantic/Faroe',  value: 'Atlantic/Faroe' },
            { label: 'Europe/Paris',  value: 'Europe/Paris' },
            { label: 'Europe/London',  value: 'Europe/London' },
            { label: 'Asia/Tbilisi',  value: 'Asia/Tbilisi' },
            { label: 'America/Cayenne',  value: 'America/Cayenne' },
            { label: 'Africa/Accra',  value: 'Africa/Accra' },
            { label: 'Europe/Gibraltar',  value: 'Europe/Gibraltar' },
            { label: 'America/Godthab',  value: 'America/Godthab' },
            { label: 'America/Danmarkshavn',  value: 'America/Danmarkshavn' },
            { label: 'America/Scoresbysund',  value: 'America/Scoresbysund' },
            { label: 'America/Thule',  value: 'America/Thule' },
            { label: 'Europe/Athens',  value: 'Europe/Athens' },
            { label: 'Atlantic/South_Georgia',  value: 'Atlantic/South_Georgia' },
            { label: 'America/Guatemala',  value: 'America/Guatemala' },
            { label: 'Pacific/Guam',  value: 'Pacific/Guam' },
            { label: 'Africa/Bissau',  value: 'Africa/Bissau' },
            { label: 'America/Guyana',  value: 'America/Guyana' },
            { label: 'Asia/Hong_Kong',  value: 'Asia/Hong_Kong' },
            { label: 'America/Tegucigalpa',  value: 'America/Tegucigalpa' },
            { label: 'America/Port-au-Prince',  value: 'America/Port-au-Prince' },
            { label: 'Europe/Budapest',  value: 'Europe/Budapest' },
            { label: 'Asia/Jakarta',  value: 'Asia/Jakarta' },
            { label: 'Asia/Pontianak',  value: 'Asia/Pontianak' },
            { label: 'Asia/Makassar',  value: 'Asia/Makassar' },
            { label: 'Asia/Jayapura',  value: 'Asia/Jayapura' },
            { label: 'Europe/Dublin',  value: 'Europe/Dublin' },
            { label: 'Asia/Jerusalem',  value: 'Asia/Jerusalem' },
            { label: 'Asia/Kolkata',  value: 'Asia/Kolkata' },
            { label: 'Indian/Chagos',  value: 'Indian/Chagos' },
            { label: 'Asia/Baghdad',  value: 'Asia/Baghdad' },
            { label: 'Asia/Tehran',  value: 'Asia/Tehran' },
            { label: 'Atlantic/Reykjavik',  value: 'Atlantic/Reykjavik' },
            { label: 'Europe/Rome',  value: 'Europe/Rome' },
            { label: 'America/Jamaica',  value: 'America/Jamaica' },
            { label: 'Asia/Amman',  value: 'Asia/Amman' },
            { label: 'Asia/Tokyo',  value: 'Asia/Tokyo' },
            { label: 'Asia/Bishkek',  value: 'Asia/Bishkek' },
            { label: 'Africa/Nairobi',  value: 'Africa/Nairobi' },
            { label: 'Pacific/Tarawa',  value: 'Pacific/Tarawa' },
            { label: 'Pacific/Enderbury',  value: 'Pacific/Enderbury' },
            { label: 'Pacific/Kiritimati',  value: 'Pacific/Kiritimati' },
            { label: 'Asia/Pyongyang',  value: 'Asia/Pyongyang' },
            { label: 'Asia/Seoul',  value: 'Asia/Seoul' },
            { label: 'Asia/Almaty',  value: 'Asia/Almaty' },
            { label: 'Asia/Qyzylorda',  value: 'Asia/Qyzylorda' },
            { label: 'Asia/Qostanay',  value: 'Asia/Qostanay' },
            { label: 'Asia/Aqtobe',  value: 'Asia/Aqtobe' },
            { label: 'Asia/Aqtau',  value: 'Asia/Aqtau' },
            { label: 'Asia/Atyrau',  value: 'Asia/Atyrau' },
            { label: 'Asia/Oral',  value: 'Asia/Oral' },
            { label: 'Asia/Beirut',  value: 'Asia/Beirut' },
            { label: 'Asia/Colombo',  value: 'Asia/Colombo' },
            { label: 'Africa/Monrovia',  value: 'Africa/Monrovia' },
            { label: 'Europe/Vilnius',  value: 'Europe/Vilnius' },
            { label: 'Europe/Luxembourg',  value: 'Europe/Luxembourg' },
            { label: 'Europe/Riga',  value: 'Europe/Riga' },
            { label: 'Africa/Tripoli',  value: 'Africa/Tripoli' },
            { label: 'Africa/Casablanca',  value: 'Africa/Casablanca' },
            { label: 'Europe/Monaco',  value: 'Europe/Monaco' },
            { label: 'Europe/Chisinau',  value: 'Europe/Chisinau' },
            { label: 'Pacific/Majuro',  value: 'Pacific/Majuro' },
            { label: 'Pacific/Kwajalein',  value: 'Pacific/Kwajalein' },
            { label: 'Asia/Yangon',  value: 'Asia/Yangon' },
            { label: 'Asia/Ulaanbaatar',  value: 'Asia/Ulaanbaatar' },
            { label: 'Asia/Hovd',  value: 'Asia/Hovd' },
            { label: 'Asia/Choibalsan',  value: 'Asia/Choibalsan' },
            { label: 'Asia/Macau',  value: 'Asia/Macau' },
            { label: 'America/Martinique',  value: 'America/Martinique' },
            { label: 'Europe/Malta',  value: 'Europe/Malta' },
            { label: 'Indian/Mauritius',  value: 'Indian/Mauritius' },
            { label: 'Indian/Maldives',  value: 'Indian/Maldives' },
            { label: 'America/Mexico_City',  value: 'America/Mexico_City' },
            { label: 'America/Cancun',  value: 'America/Cancun' },
            { label: 'America/Merida',  value: 'America/Merida' },
            { label: 'America/Monterrey',  value: 'America/Monterrey' },
            { label: 'America/Matamoros',  value: 'America/Matamoros' },
            { label: 'America/Mazatlan',  value: 'America/Mazatlan' },
            { label: 'America/Chihuahua',  value: 'America/Chihuahua' },
            { label: 'America/Ojinaga',  value: 'America/Ojinaga' },
            { label: 'America/Hermosillo',  value: 'America/Hermosillo' },
            { label: 'America/Tijuana',  value: 'America/Tijuana' },
            { label: 'America/Bahia_Banderas',  value: 'America/Bahia_Banderas' },
            { label: 'Asia/Kuala_Lumpur',  value: 'Asia/Kuala_Lumpur' },
            { label: 'Asia/Kuching',  value: 'Asia/Kuching' },
            { label: 'Africa/Maputo',  value: 'Africa/Maputo' },
            { label: 'Africa/Windhoek',  value: 'Africa/Windhoek' },
            { label: 'Pacific/Noumea',  value: 'Pacific/Noumea' },
            { label: 'Pacific/Norfolk',  value: 'Pacific/Norfolk' },
            { label: 'Africa/Lagos',  value: 'Africa/Lagos' },
            { label: 'America/Managua',  value: 'America/Managua' },
            { label: 'Europe/Amsterdam',  value: 'Europe/Amsterdam' },
            { label: 'Europe/Oslo',  value: 'Europe/Oslo' },
            { label: 'Asia/Kathmandu',  value: 'Asia/Kathmandu' },
            { label: 'Pacific/Nauru',  value: 'Pacific/Nauru' },
            { label: 'Pacific/Niue',  value: 'Pacific/Niue' },
            { label: 'Pacific/Auckland',  value: 'Pacific/Auckland' },
            { label: 'Pacific/Chatham',  value: 'Pacific/Chatham' },
            { label: 'America/Panama',  value: 'America/Panama' },
        
      ],
    }
  },
  components: {
     vSelect,
    flatPickr
  },
  computed: {
    event ()     { 
      if(!this.$route.params.id){
        return {
          'status':false,
          'image_url':'/600x400.png',
          'title':'',
          'description':'',
          'event_time':'',
          'org_id':this.$store.state.AppActiveUser.org_id
        }
      }else{
        if(this.formData.image_url != ""){
            this.add_image_before = false;
            this.add_image_after = true;
            this.upload_image = 1
            this.add_new = false
            this.add_update = true
            this.reset_btn = false
            this.canel_btn = true
             this.remove_image_list = true;
            this.add_image_list = false;
        }
        return this.$store.getters['event/getEvent'](this.$route.params.id)
      }
    },
    timezone: {
        get () {            
          return this.formData['timezone']
        },
        set (obj) {

        console.log("testasas",obj.value)

          this.formData['timezone'] = obj.value
        }
    },
   
    status_model: {
      get () {
        return this.formData['status']=='1'?true:false
      },
      set (status) {
        this.formData['status'] = status?'1':'0'
      }
    },
    event_time: {
      get () {
        return this.formData['event_time']
      },
      set (date) {
        this.formData['event_time'] = date
      }
    },
   event_start_time_model: {
      get () {
        return this.formData['event_start_time']
      },
      set (date) {
        if(this.formData['event_start_time'] > this.formData['event_end_time']){
          this.errEndTime = true
        }else{
          this.errEndTime = false
        }
        this.formData['event_start_time'] = date
      }
    },
     event_end_time_model: {
      get () {
        return this.formData['event_end_time']
      },
      set (date) {
        if(this.formData['event_start_time'] > this.formData['event_end_time']){
          this.errEndTime = true
        }else{
          this.errEndTime = false
        }
        this.formData['event_end_time'] = date
      }
    }, event_start_time_model: {
      get () {
        return this.formData['event_start_time']
      },
      set (date) {
        if(this.formData['event_start_time'] > this.formData['event_end_time']){
          this.errEndTime = true
        }else{
          this.errEndTime = false
        }
        this.formData['event_start_time'] = date
      }
    },
     event_end_time_model: {
      get () {
        return this.formData['event_end_time']
      },
      set (date) {
        if(this.formData['event_start_time'] > this.formData['event_end_time']){
          this.errEndTime = true
        }else{
          this.errEndTime = false
        }
        this.formData['event_end_time'] = date
      }
    },
    validateForm () {
      return !this.errors.any() && this.errEndTime == 0 &&  this.formData.title !== '' && this.formData.description !== '' && this.formData.event_time !== '' && this.upload_image == 1
    }
  },
  methods: {
     update_avatar_list (e) {
      this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      if((img.height !== 400 || img.width !== 400) || (sizeInMB > 2)){ 
                      //  if(sizeInMB > 2)
                      // {
                            this.errImageupload = 1
                            this.$refs.image_url_list.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 400*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
       this.$vs.loading()
      this.$store.dispatch('event/uploadImage', {image:e.target.files[0], event_id:this.$route.params.id}).then((res) => {
        this.formData['image_url_list'] = res
          this.remove_image_list = true;
          this.$refs.image_url_list.value = ''
          this.upload_image = 1
        this.add_image_list = false;
         this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
      }
    }, 200);
  },
    remove_images_list(e) {
      this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                     if((img.height !== 400 || img.width !== 400) || (sizeInMB > 2)){
                      // if(sizeInMB > 2)
                      // {
                            this.errImageupload = 1
                            this.$refs.image_url_list.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 400*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$store.dispatch('event/removeImage', {image:e.target.files[0], event_id:this.$route.params.id,oldImage:this.formData['image_url']}).then((res)=>{
        this.formData['image_url_list'] = res
          this.remove_image_list = true;
          this.upload_image = 1
        this.add_image_list = false;
        this.$refs.image_url_list.value = ''
         this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
          }
    }, 200);
    },
      cancel_data(){
        this.$router.push('/event/all/').catch(() => {})
    },
    save_changes () {
      if (!this.validateForm) return
      const d = new Date(this.formData['event_time'])
      this.formData['event_time'] = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate() + ' 00:00:00'
      this.$vs.loading()
      if(this.formData['event_id']){
        this.$store.dispatch('event/updateEvent', Object.assign({}, this.formData)).then((res) => {
              this.$vs.loading.close()  
              const success = 'Event Updated Successfully...!!!'
              this.notif_sucess(success) 
          }).catch((error) => { 
            if( error.message == "Request failed with status code 423"){
                          error.message = 'Event is created by same name..!!!'
                      }else{
                          error = error.message
                      } 
              this.$vs.loading.close()
              this.notif(error)
            })
      }else{
        this.$store.dispatch('event/addEvent', Object.assign({}, this.formData)).then((res) => {
              this.$vs.loading.close()  
              const success = 'Event added successfully...!!!'
              this.notif_sucess(success) 
          }).catch((error) => { 
            if( error.message == "Request failed with status code 423"){
                          error.message = 'Event is created by same name..!!!'
                      }else{
                          error = error.message
                      } 
              this.$vs.loading.close()
              this.notif(error)
            })
      }
    },
      notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
     notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
    reset_data () {
      this.formData = Object.assign({}, this.event)
    },
      notif_failed (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"danger"
      })
    },
     update_avatar (e) {
      this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                     if((img.height !== 400 || img.width !== 600) || (sizeInMB > 2)){
                    //  if(sizeInMB > 2)
                    //   {
                            this.$refs.org_profile_image.value = ''
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 600*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
       this.$vs.loading()
      this.$store.dispatch('event/uploadImage', {image:e.target.files[0], event_id:this.$route.params.id}).then((res) => {
         this.formData['image_url']=res
        this.add_image_before = false;
        this.add_image_after = true;
        this.$refs.org_profile_image.value = ''
        this.upload_image = 1
              this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
      }
    }, 200);
  },
    remove_images(e) {
      this.$vs.loading()
                let file = e.target.files[0];
                let reader = new FileReader();
                const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                     if((img.height !== 400 || img.width !== 600) || (sizeInMB > 2)){
                    //  if(sizeInMB > 2)
                    //   {
                            this.$refs.org_profile_image.value = ''
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 600*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
        this.$vs.loading()
        this.$store.dispatch('event/removeImage', {image:e.target.files[0], event_id:this.$route.params.id,oldImage:this.formData['image_url']}).then((res)=>{
          this.$refs.org_profile_image.value = ''
             this.formData['image_url']=res
        this.add_image_before = false;
        this.add_image_after = true;
        this.upload_image = 1
              this.$vs.loading.close()
        }).catch((error) => { 
                    this.$vs.loading.close()
                    this.notif(error)     
          })
           }
    }, 200);
    },
  },
  created () {
    // this.$store.registerModule('event', moduleEvent)
    if(!this.event){
      this.$store.dispatch('event/fetchEvents').then((res)=>{
        this.formData = Object.assign({}, this.event)
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
                })
    }else{
      this.formData = Object.assign({}, this.event)
    }
    
  },
  beforeDestroy() {
    // this.$store.unregisterModule('event')
  }
}
</script>
